import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../../components/root/Layout"
import Img from "gatsby-image"
import Linkedin from "../../../assets/linkedin.svg"
import Twitter from "../../../assets/twitter.svg"
import SEO from "../../components/root/Seo"
import Confetti from "../../../assets/confetti.svg"

export default function MeetTheTeam({ data }) {
  const teamMembers = data.allPrismicTeamMember.edges.map(({ node }) => ({
    ...node.data,
  }))
  return (
    <Layout>
      <SEO title="Meet The Team" />
      <div className="bg-brand-blue-light text-white mb-16">
        <div className="container py-8 md:py-16">
          <h1 className="text-5xl md:text-6xl leading-tight font-bold mb-3">
            Meet the team
          </h1>
          <p className="text-2xl">The people behind ArchUp</p>
        </div>
      </div>
      <div className="container pb-8 md:pb-16 lg:pb-24 text-gray-800">
        {teamMembers.map(
          (
            { bio, linkedin, role, name, profile_photo, twitter, accent_color },
            index
          ) => (
            <div className="grid grid-cols-1 md:grid-cols-3 mb-12 md:mb-8 relative">
              <Img
                style={{ zIndex: 1 }}
                fluid={profile_photo.localFile.childImageSharp.fluid}
                alt={`${name}'s portrait`}
                className="relative profile-photo w-64 h-64 mb-5 sm:mb-0 md:w-full md:max-w-xs md:h-full rounded md:rounded mx-auto "
              />
              <Confetti
                className={`text-gray-300 fill-current h-32 w-32 absolute ${
                  index % 2 === 0
                    ? "top-0 left-0 -ml-16 -mt-16"
                    : "top-0 right-0 -mr-16 -mt-16"
                }`}
              />

              <div className="md:px-4 col-span-2 flex justify-center flex-col">
                <h4 className="text-2xl md:text-3xl font-bold">{name.text}</h4>
                <p className="text-xl font-medium mb-3">{role.text}</p>
                {bio.raw.map(({ text }) => (
                  <p className="mb-3">{text}</p>
                ))}

                {linkedin.url && (
                  <a href={linkedin.url}>
                    <Linkedin className="mt-2 fill-current hover:text-brand-blue h-8 w-8" />
                  </a>
                )}
                {twitter.url && (
                  <a href={twitter.url}>
                    <Twitter className="mt-2 fill-current hover:text-brand-blue h-8 w-8" />
                  </a>
                )}
              </div>
            </div>
          )
        )}
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  {
    allPrismicTeamMember {
      edges {
        node {
          data {
            accent_color
            name {
              text
            }
            bio {
              raw
            }
            linkedin {
              url
            }
            twitter {
              url
            }
            role {
              text
            }
            profile_photo {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 380) {
                    ...GatsbyImageSharpFluid_noBase64
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
